export default {
  namespaced: true, 
  state: {
    work: [
      {
        title: 'Управляющий', 
        email: 'hrbp0510@gmail.com',
        mobile: '+7 (912) 020-04-01',
        location: 'г. Ижевск',
        cost: 'з/п от 40 000 рублей',
        req: [
          'опыт работы от 1-3 года', 
          'аналитический склад ума', 
          'уверенный пользователь ПК, в том числе социальные сети и мессенджеры', 
          'опыт работы в схожей сфере или готовность учиться и расти', 
        ],
        respons: [
          'осуществлять контроль работы сотрудников, в том числе составлять графики выхода на работу (администраторы, педагоги, уборщица, менеджер по продажам, дворник)',
          'вести установленную отчетность (график работы персонала, учет доходов-расходов и тд.)',
          'составлять и контролировать график занятий',
          'решать спорные ситуации',
          'контролировать оплату абонементов, разовых занятий',
          'участвовать в проведении собеседований',
          'проводить мониторинг конкурентов, редактировать портрета ЦА',
          'осуществлять поиск подрядчиков для проведения мастер классов, дополнительных мероприятий для детей и их родителей',
          'совместно с собственником составлять программу летних лагерей'
        ],
        cond: [
          'гибридный график работы - Ты сам устанавливаешь часы работы для собственника главное Результат',
          'официальное трудоустройство',
          'свободу действий в пределах своих компетенций',
          'наставничество от собственника',
          'развитие управленческих навыков'
        ],
      },
      {
        title: 'Менеджер по работе с клиентами',
        email: 'evkazan1984@mail.ru',
        mobile: '+7(919)904-12-64', 
        location: 'г. Ижевск', 
        cost: 'з/п от 80 000 рублей', 
        respons: [
          'активный поиск клиентов', 
          'ведение клиентской базы',
          'оформление и отработка заявок', 
          'проведение презентаций продукции и услуг компании',
          'проведение переговоров, заключение договоров', 
          'контроль оплаты по договорам', 
          'консультирование клиентов по продукции и услугам компании',
        ],
        req: [
          'опыт работы от года',
          'грамотная письменная и устная речь'
        ],
        cond: [
          'официальное трудоустройство',
          'график работы с 09.00 до 18.00',
          'обучение за счет компании'
        ]
      },
      {
        title: 'Слесарь МСР',
        email: 'evkazan1984@mail.ru',
        cost: 'з/п 55 000 рублей',
        respons: [
          'сборка узлов и агрегатов из комплектующих',
          'слесарные работы',
          'погрузка-разгрузка деталей',
          'работа на ленточнопильном станке по металлу'
        ],
        req: [
          'образование не ниже среднего специального',
          'опыт работы от 2-х лет'
        ],
        cond: [
          'официальное трудоустройство',
          'график работы Пн-пт 07.00 до 16.00',
          'комфортные условия труда (раздевалки, душевые, комната для приема пищи и отдыха)',
          'предоставление спецодежды'
        ]
      },
      {
        title: 'Оператор станков ЧПУ',
        email: 'evkazan1984@mail.ru',
        cost: 'з/п от 70 000 рублей',
        respons: [
          'смена деталей',
          'контроль размеров',
          'чтение чертежей',
          'контроль режущего инструмента',
        ],
        req: [
          'опыт работы от 1 года (рассматриваем кандидатов без опыта работы, но с профильным образованием)',
          'образование средне специальное.'
        ],
        cond: [
          'официальное трудоустройство',
          'комфортные условия труда (раздевалки, душевые, комната для приема пищи и отдыха)',
          'предоставление спецодежды',
          'работа по графику 3/3, 12 часов (без ночных смен)'
        ]
      }, 
    ]
  }
}